import React from 'react'

function Page404() {
  return (
    <div className="center-div full-page" style={{fontSize: 30}}>
      404 Not Found
    </div>
  )
}

export default Page404;